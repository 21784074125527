var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"showBreadcrumbs":true,"pageTitle":"Underwriting Qualifications","topActionButtonPrimary":_vm.editing &&
    _vm.editing.ratingValidations &&
    _vm.editing.ratingValidations.hasUnderwritingResponses
      ? {
          text: 'Next',
          key: 'next',
          disabled: !_vm.formsAreValid,
          loading: _vm.makingApiRequest || _vm.loading
        }
      : undefined,"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItemHandler}},[_c('QuoteSteps',{attrs:{"currentStep":5,"canClick":_vm.formsAreValid}}),(_vm.getUnderwritingQuestionsSchema.length)?[_c('div',{staticClass:"mb-24"},[_c('UnderwriterInfo')],1),(
        !_vm.validationData || (_vm.validationData && !_vm.validationData.formIsValid)
      )?_c('custom-alert',{attrs:{"title":"Notice","show":true,"dismissible":false,"message":_vm.message,"type":"warning"}}):_vm._e(),(!_vm.hasRatings)?_c('OldRatingCustomAlert',{attrs:{"url":_vm.quoteLink}}):_vm._e(),(
        _vm.getCurrentUser &&
          (_vm.getCurrentUser.role === 'admin' ||
            _vm.getCurrentUser.role === 'underwriting' ||
            _vm.isSAFEUPCR)
      )?_c('el-checkbox',{staticClass:"mt-12",attrs:{"disabled":_vm.quoteHasBeenSubmitted || _vm.quoteHasBeenDeleted},on:{"change":_vm.checkNoChangedHandler},model:{value:(_vm.checkNo),callback:function ($$v) {_vm.checkNo=$$v},expression:"checkNo"}},[_vm._v("Check No")]):_vm._e(),_c('form-builder',{ref:"underwriting",staticClass:"mt-4",attrs:{"schemas":_vm.getUnderwritingQuestionsSchema,"disabled":_vm.quoteHasBeenSubmitted || _vm.quoteHasBeenDeleted,"formValues":_vm.getQualificationValues},on:{"formFieldChanged":_vm.formFieldChangedHandler,"validationChanged":_vm.validationChangeHandler}}),(_vm.specialQuestionsAnswered)?_c('form-builder',{ref:"approval",staticClass:"mt-4",attrs:{"schemas":_vm.approvalQuestions,"disabled":_vm.quoteHasBeenSubmitted || _vm.quoteHasBeenDeleted,"formValues":_vm.editing},on:{"formFieldChanged":_vm.approvalChangedHandler,"validationChanged":_vm.approvalValidationHandler,"formChangedVisibly":_vm.approvalChangeVisiblyHandler}}):_vm._e(),_c('ModalMessage',{attrs:{"name":"messageModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":_vm.description,"showClose":true,"buttons":[
        {
          text: `Okay`,
          classList: [''],
          click: () => _vm.$modal.hide('messageModal')
        }
      ]}}),_c('ModalBase',{attrs:{"name":"newConstruction","size":"medium","clickToClose":false,"showClose":false,"title":"New Construction","loading":_vm.loading}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"mb-8 text-center text-base"},[_vm._v(" Is this quote for a new construction? ")]),_c('div',{staticClass:"w-full flex justify-end"},[_c('IAButton',{staticClass:"mr-2",attrs:{"type":"text","click":() => _vm.newConstruction('No'),"title":"No"}}),_c('IAButton',{attrs:{"click":() => _vm.newConstruction('Yes'),"title":"Yes"}})],1)])]),_c('div',{ref:"endOfPage",attrs:{"id":"endOfPage"}})]:_c('ResourceNotFound',{attrs:{"title":"Sorry! Underwriting questions not found.","subtitle":"Please try again","actionButtonTitle":"Back to ratings","actionRedirectPath":_vm.editing && _vm.editing._id ? `/quotes/${_vm.editing._id}/property-ratings` : ''}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }