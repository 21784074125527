
import Vue from "vue";
import underwritingForms from "@/forms/shared/underwritingQuestions";
import { approvalQuestions } from "@/helpers/underwritingQualifications/approvalQuestions";
import {
  quoteMapActions,
  quoteMapGetters,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import { objectDifference } from "@/helpers";
import { IQuote } from "@/store/modules/quote/types";
import { defaultUnderwritingResponse } from "@/helpers/defaultObjects";
import { authMapGetters } from "@/store/modules/auth";
import { get, isNull, isUndefined, omitBy } from "lodash";
import { isColonial, hasStateWide } from "@/forms/utils";
import OldRatingCustomAlert from "./RatingApplication/OldRatingCustomAlert.vue";
import {
  validateUnderWritingResponse,
  isSpecialResponse,
  getSpecificQuestion
} from "@/helpers/underwritingQualifications/validateUnderWritingResponse";
import { getFormattedUnderwritingQuestions } from "@/helpers/underwritingQualifications/getFormattedUnderwritingQuestions";
import * as types from "@/store/mutation-types";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";
import { allowSAFEUPCR } from "@/helpers/generalHelpers";
import { singleUnderwritersQuestion } from "@/helpers/underWritingQuestionsDefault";

export default Vue.extend({
  name: "underwriting-qualifications",
  components: {
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    QuoteSteps: () => import("@/views/shared/quotes/QuoteSteps.vue"),
    OldRatingCustomAlert,
    UnderwriterInfo
  },
  props: {
    quoteId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data(): any {
    return {
      underwritingForms,
      validationData: {},
      approvalFormIsValid: false,
      checkNo: false,
      loading: false,
      message:
        "Please Answer All Questions Before Calling your Underwriter for Approval Code.\nThese questions must be answered Yes or No to proceed",
      description: "",
      approvalQuestions: approvalQuestions,
      loadingText: ""
    };
  },
  created() {
    const { underwritingResponses, underwritingQuestions } = this.editing;
    let questionKeys = underwritingQuestions.map(
      (question: any) => question.key
    );
    const keys = Object.keys(defaultUnderwritingResponse).filter(
      (key: string) =>
        key !== "underWriterApprovalCode" &&
        key !== "approvedQuestionableAnswers"
    );
    questionKeys = questionKeys.filter(
      (questionKey: string) => !keys.includes(questionKey)
    );

    for (let questionKey of questionKeys) {
      this.editUnderwritingResponseField({
        key: questionKey as any,
        value: get(
          this.originalQuote,
          `underwritingResponses.${questionKey}`,
          ""
        )
      });
    }

    this.checkNo = keys.every(
      (key: string) => underwritingResponses[key] === "No"
    );
    const wasRedirectedWithError = this.$route.query.error;
    if (wasRedirectedWithError) {
      this.scrollFirstInstanceOfErrorIntoView();
    }
  },
  methods: {
    ...quoteMapActions([
      "saveUnderwritingResponses",
      "updateQuote",
      "saveQuotePropertyFields"
    ]),
    ...quoteMapMutations({
      editField: "SET_EDIT_FIELD",
      setEdit: "SET_EDIT",
      editUnderwritingResponseField: types.EDIT_UNDERWRITING_RESPONSE_FIELD
    }),
    formFieldChangedHandler({ key, value }: { key: string; value: any }) {
      const question = this.getUnderwritingQuestionsSchema.find(
        (question: any) => {
          const singleQuestion = singleUnderwritersQuestion(question);
          return singleQuestion?.properties.key === key;
        }
      );
      const singleQuestion = singleUnderwritersQuestion(question);
      const response = {
        answer: value,
        question: singleQuestion?.properties.preamble,
        isValid: false
      };
      const errorMessage = validateUnderWritingResponse(
        this.editing,
        key,
        value
      );

      const hasAdditionalMessage = getSpecificQuestion(
        this.editing.underwritingQuestions,
        key
      );

      if (errorMessage) {
        this.description = errorMessage;
        this.$modal.show("messageModal");
        response.isValid = false;
      } else if (hasAdditionalMessage) {
        const {
          additionalModalCheck = "",
          additionalModalMessage = ""
        } = hasAdditionalMessage;
        if (additionalModalCheck.toLowerCase() === value.toLowerCase()) {
          this.description = additionalModalMessage;
          this.$modal.show("messageModal");
        }
      } else {
        this.description = "";
        response.isValid = true;
      }
      this.editField({
        key: `underwritingResponses.${key}` as any,
        value: response
      });
      this.save(false);
    },
    checkNoChangedHandler(val: boolean) {
      const newValues = {} as any;

      this.getUnderwritingQuestionsSchema.forEach((question: any) => {
        const key = get(question, "children[0].properties.key");
        if (key) {
          let value = val ? "No" : "Yes";
          if (question && question !== undefined) {
            const singleQuestion = singleUnderwritersQuestion(question);
            newValues[key] = {
              answer: value,
              question: singleQuestion?.properties.preamble,
              isValid: !validateUnderWritingResponse(this.editing, key, value)
            };
          }
        }
      });
      this.editField({ key: "underwritingResponses", value: newValues });

      this.checkNo = true;
      this.save();
    },
    async approvalChangeVisiblyHandler({
      key,
      value
    }: {
      key: string;
      value: string;
    }) {
      const matchedFields: Record<string, string> = {
        approvalCode: "underWriterApprovalCode",
        hasUnderwriterApproved: "approvedQuestionableAnswers"
      };
      this.editField({
        key: `underwritingResponses.${matchedFields[key]}`,
        value
      });
      if (this.loading) {
        return;
      }
      this.save(false);
    },
    approvalChangedHandler({ key, value }: { key: string; value: any }) {
      if (key === "approvalCode" || key === "hasUnderwriterApproved") {
        this.editField({ key: key as any, value });
      }
    },
    async newConstruction(value: string) {
      try {
        this.loading = true;
        await this.updateQuote({
          id: this.$route.params.quoteId,
          update: {
            newConstruction: value
          }
        });
      } catch (error) {
        this.$appNotifyError(
          "There was an error saving your responses. Try again later."
        );
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.$modal.hide("newConstruction");
        this.$router.push(
          `/quotes/${this.$route.params.quoteId}/rating-application`
        );
      }
    },
    async checkBlacklistedAgent() {
      const createdByExemptedUserRole = [
        "admin",
        "underwriting",
        "claims",
        "billing",
        "dataEntry"
      ].includes(this.originalQuote.createdByData.role);
      if (!createdByExemptedUserRole) {
        const blacklistedAgents = ["frmk", "cll-frmk", "aga-frmk"];
        const originalAgentCode = get(this.originalQuote, "agentCode", "");
        const agentCode = originalAgentCode.trim().toLowerCase();

        if (blacklistedAgents.includes(agentCode)) {
          this.$modal.show("newConstruction");
        } else {
          await this.newConstruction("No");
        }
      } else {
        await this.newConstruction("No");
      }
    },
    async toolbarSelectItemHandler(event: string) {
      if (event === "next") {
        await this.checkBlacklistedAgent();
      } else {
        await this.save();
      }
    },
    async save(doEditMutation = true): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Saving response. Please wait...";
        const response = await this.saveUnderwritingResponses({
          quoteId: this.$route.params.quoteId,
          underwritingResponses: { ...this.updatedFields.underwritingResponses }
        });

        if (this.updatedFields.approvalCode) {
          await this.saveQuotePropertyFields({
            id: this.$route.params.quoteId,
            payload: {
              approvalCode: this.updatedFields.approvalCode,
              hasUnderwriterApproved: this.updatedFields.hasUnderwriterApproved
            }
          });
        }
        if (doEditMutation) this.setEdit(response);
        this.editField({
          key: `ratingValidations.hasUnderwritingResponses` as any,
          value: response.ratingValidations.hasUnderwritingResponses
        });
      } catch (error) {
        this.$appNotifyError(
          "There was an error saving your responses. Try again later."
        );
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    validationChangeHandler(event: any) {
      this.validationData = event;
    },
    approvalValidationHandler(event: any) {
      const { formIsValid } = event;
      this.approvalFormIsValid = formIsValid;
    },
    scrollFirstInstanceOfErrorIntoView() {
      const { fieldsWithErrors } = this.validationData;
      if (fieldsWithErrors && fieldsWithErrors.length) {
        const field = fieldsWithErrors[0];
        const element = document.querySelector(`#field_field_${field}`);
        if (element) {
          const elementOffset = element.getBoundingClientRect().top;
          window.scrollTo({
            top: elementOffset - window.innerHeight / 2,
            behavior: "smooth"
          });
        }
      }
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    ...quoteMapGetters(["getQuoteById"]),
    ...authMapGetters(["getCurrentUser"]),
    underwritingResponses(): any {
      return {
        ...get(this.editing, "underwritingResponses", {}),
        companyNumber: get(this.editing, "ratingSelectedData.companyNumber"),
        policyType: get(this.editing, "policyType")
      };
    },
    isSAFEUPCR(): boolean {
      return allowSAFEUPCR(this.editing);
    },

    formsAreValid(): any {
      if (this.validationData && this.validationData.formIsValid) {
        return true;
      } else if (
        this.validationData &&
        !this.formIsValid &&
        this.validationData.fieldsWithErrors &&
        this.validationData.fieldsWithErrors.length
      ) {
        const hasValidSpecialQuestions = this.validationData.fieldsWithErrors.every(
          (field: string) => {
            return this.specialQuestionsKeys.includes(field);
          }
        );
        return (
          hasValidSpecialQuestions &&
          this.approvalFormIsValid &&
          this.specialQuestionsAnswered &&
          this.editing.hasUnderwriterApproved
        );
      }
      return false;
    },
    updatedFields(): any {
      const edited = this.editing;
      const original = this.getQuoteById(this.$route.params.quoteId);
      return edited && original
        ? objectDifference(edited, original, [
            "underwritingResponses.underWriterApprovalCode"
          ])
        : {};
    },
    specialQuestionsKeys(): any[] {
      const questions = get(this.editing, "underwritingQuestions", {});

      return questions
        .filter((question: any) => {
          return question.needsApprovalCode;
        })
        .map((question: any) => {
          return question.key;
        });
    },
    originalQuote(): IQuote {
      return this.getQuoteById(this.$route.params.quoteId);
    },
    getUnderwritingQuestionsSchema() {
      const formattedQuestions: any[] = getFormattedUnderwritingQuestions(
        this.editing
      );
      return formattedQuestions;
    },
    getUnderWriterQuestions(): Record<string, any> {
      return get(this.editing, "underwritingQuestions", {});
    },
    questionResponses(): Record<string, any> {
      const responses = omitBy(
        get(this.editing, "underwritingResponses", {}),
        (response: any) =>
          response === "" || isNull(response) || isUndefined(response)
      );
      return responses;
    },
    specialQuestionsAnswered(): boolean {
      const responses = this.questionResponses;
      const questions = this.getUnderWriterQuestions;
      let responseKeys = Object.keys(responses).filter((key: string) => {
        return (
          !["companyNumber", "formType"].includes(key) &&
          this.specialQuestionsKeys.includes(key)
        );
      });

      if (this.specialQuestionsKeys && !this.specialQuestionsKeys.length)
        return true;
      return responseKeys.some((key: string) => {
        const underWritingQuestion = questions?.find((question: any) => {
          return question.key === key;
        });
        return isSpecialResponse(responses[key].answer, underWritingQuestion);
      });
    },
    companyNumber(): number | null {
      if (this.editing && this.editing.ratingSelectedData) {
        return this.editing.ratingSelectedData.companyNumber;
      }
      return null;
    },
    quoteHasBeenSubmitted(): boolean {
      const submittedStatuses = ["submitted", "received", "rejected"];
      return !!(
        this.editing &&
        submittedStatuses.includes(this.editing.status) &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasPolicyNumber
      );
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    },
    getQualificationValues() {
      let values = {} as any;
      const underwritingResponses = get(
        this.editing,
        "underwritingResponses",
        {}
      );
      const responseKeys = Object.keys(underwritingResponses);
      responseKeys.forEach((key: string) => {
        const answer =
          key !== "underWriterApprovalCode" &&
          key !== "approvedQuestionableAnswers"
            ? underwritingResponses[key].answer || ""
            : underwritingResponses[key] || "";
        values[key] = answer;
      });
      return values;
    },
    isColonial(): boolean {
      if (this.companyNumber) return isColonial([this.companyNumber]);
      return false;
    },
    isStateWide(): boolean {
      if (this.companyNumber) return hasStateWide([this.companyNumber]);
      return false;
    },
    hasRatings(): boolean {
      return (
        this.editing &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasSelectedRating
      );
    }
  }
});
