import { useFormSection, useField } from "@/components/FormBuilder/Helpers";
import { quoteApplicationForm } from "./quote.application";

const yesNoOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" }
];

export default [
  ...useFormSection(
    [
      useField(
        {
          type: "radio_button_group",
          preamble: "Have there been any updates to the property?",
          key: "propertyUpdates",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble: "Is the dwelling a secondary or seasonal residence?",
          key: "dwellingType",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Does the risk have any existing damage including flood damages which have not been properly repaired",
          key: "damagingFloods",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is the risk isolated, not visible to neighbors, inaccessible by emergency vehicles or situated on more than a total of 20 acres including adjoining property?",
          key: "riskIsIsolated",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is there any daycare, farming other business activities or rented rooms on the premises?",
          key: "businessOnPremises",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Are there any dogs from the restricted list or exotic pets on the premises?",
          key: "dogsOrExoticBreeds",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is there an unfenced swimming pool, hot tub, similar structure or does the home have an indoor pool on the premises?",
          key: "unfencedPool",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is any structure on the premises being constructed, reconstructed, removed or remodeled?",
          key: "structureUnderConstruction",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble: "Is there any unusual/atypical liability exposure",
          key: "unusualLiabilityExposure",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is the dwelling of a unique design which could include but not limited to mobile/manufactured homes, homes with historical plaques, do it yourself, underground, log, dome, earth homes, greenhouse, shell, Morgan, portable, converted commercial, and iron clad buildings",
          key: "uniqueDesign",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is the dwelling or any other structure located wholly or partially over water",
          key: "overWater",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is the current roof an overlay of the previous roof or are any portions of the roof flat over living areas?",
          key: "overlayOverLivingAreas",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble: "Is the dwelling a mobile or modular home?",
          key: "isMobile",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Has the applicant had a policy that has been cancelled, non-renewed, or rescinded on the basis of material misrepresentation in the past 3 years?",
          key: "hasRescindedPolicy",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble: "Is the house vacant?",
          key: "houseIsVacant",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble: "Does the dwelling have more than 2 solar panels",
          key: "dwellingHasMoreThan2SolarPanels",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble: "Does the risk have a basement with a sump pump?",
          key: "hasBasementWithSumpPump",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Has the applicant(s) ever been convicted or currently under the investigation for arson, fraud, or other insurance related offenses",
          key: "isConvicted",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      ),
      useField(
        {
          type: "radio_button_group",
          preamble:
            "Is the dwelling on a barrier island or on land completely surrounded by water",
          key: "surroundedByWater",
          options: yesNoOptions,
          required: true
        },
        ["mb-8"]
      )
    ],
    {
      title: "Questions"
    }
  ),
  ...quoteApplicationForm
];
