import { IQuote } from "@/store/modules/quote/types";
import { IUnderWritingQuestion } from "@/store/modules/underWritingQuestion/types";

/** 
  @arg quote: {IQuote} quote being edited
  @arg key: underwriting response key
  @arg value: underwriting response value = "Yes" or "No"
  @returns string (error Message) || void if response is valid
*/
export function validateUnderWritingResponse(
  quote: IQuote,
  key: string,
  value: string
): string | void {
  const underWritingQuestion = getSpecificQuestion(
    quote.underwritingQuestions,
    key
  );
  if (
    isSpecialResponse(value, underWritingQuestion) &&
    quote.underwritingResponses &&
    quote.underwritingResponses.underWriterApprovalCode &&
    quote.underwritingResponses.approvedQuestionableAnswers
  ) {
    return;
  }
  if (underWritingQuestion && value !== underWritingQuestion.validResponse) {
    return underWritingQuestion?.errorMessage;
  }
}

export function isSpecialResponse(
  answer: string,
  underWritingQuestion?: any
): boolean {
  return (
    !!underWritingQuestion &&
    underWritingQuestion.needsApprovalCode &&
    answer !== underWritingQuestion.validResponse
  );
}

export function getSpecificQuestion(
  underwritingQuestions: Record<string, any> | undefined,
  questionKey: string
): Partial<IUnderWritingQuestion> | undefined {
  if (!underwritingQuestions) return;
  return underwritingQuestions?.find((question: any) => {
    return question.key === questionKey;
  });
}
