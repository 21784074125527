import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useSelectField,
  useField,
  useFormFieldGroup
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";

export const quoteApplicationForm: FormBlock[] = [
  ...useFormSection([
    useField(
      {
        key: "swimmingPoolOnPremises",
        preamble: "Is there a swimming pool on the premises?",
        required: true,
        type: "radio_button_group",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ]
      },
      ["mb-8"]
    ),
    useField(
      {
        key: "poolApprovedFence",
        preamble: "Approved Fence?",
        required: true,
        type: "radio_button_group",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ],
        conditions: {
          and: [
            {
              field: "swimmingPoolOnPremises",
              value: "Yes",
              operator: "equals"
            }
          ]
        }
      },
      ["mb-4"]
    ),
    useField(
      {
        key: "poolDivingBoard",
        preamble: "Diving Board",
        required: true,
        type: "radio_button_group",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ],
        conditions: {
          and: [
            {
              field: "swimmingPoolOnPremises",
              value: "Yes",
              operator: "equals"
            }
          ]
        }
      },
      ["mb-4"]
    ),
    useField({
      key: "poolSlide",
      preamble: "Slide?",
      required: true,
      type: "radio_button_group",
      options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ],
      conditions: {
        and: [
          {
            field: "swimmingPoolOnPremises",
            value: "Yes",
            operator: "equals"
          }
        ]
      }
    })
  ]),
  ...useFormSection([
    useField(
      {
        key: "buildingUpdates",
        preamble: "Have there been any updates to the property?",
        required: true,
        type: "radio_button_group",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ]
      },
      ["mb-8"]
    ),
    ...useFormFieldGroup(
      [
        useSelectField(
          {
            key: "wiringUpdateYear",
            label: "Wiring Update (Year)",
            required: false,
            options: selectOptions.yearOptions,
            filterable: true,
            allowFirstOption: true,
            conditions: {
              and: [
                {
                  field: "buildingUpdates",
                  value: "Yes",
                  operator: "equals"
                }
              ]
            }
          },
          ["mb-4"]
        ),
        useSelectField(
          {
            key: "plumbingUpdateYear",
            label: "Plumbing Update (Year)",
            required: false,
            options: selectOptions.yearOptions,
            filterable: true,
            allowFirstOption: true,
            conditions: {
              and: [
                {
                  field: "buildingUpdates",
                  value: "Yes",
                  operator: "equals"
                }
              ]
            }
          },
          ["mb-4"]
        ),
        useSelectField(
          {
            key: "heatingUpdateYear",
            label: "Heating Update (Year)",
            required: false,
            options: selectOptions.yearOptions,
            filterable: true,
            allowFirstOption: true,
            conditions: {
              and: [
                {
                  field: "buildingUpdates",
                  value: "Yes",
                  operator: "equals"
                }
              ]
            }
          },
          ["mb-4"]
        )
      ],
      { layout: "3-col" }
    )
  ])
];
