import { useField, useFormSection } from "@/components/FormBuilder/Helpers";
import { IQuote } from "@/store/modules/quote/types";
import { get } from "lodash";
import * as selectOptions from "@/helpers/selectOptions";

export function getFormattedUnderwritingQuestions(quote: IQuote): any[] {
  const formattedQuestions: any[] = [];
  quote?.underwritingQuestions?.forEach(
    (question: {
      _id?: string;
      question: string;
      key: string;
      validResponse: string;
      companies: number[];
      policyType: string;
      needsApprovalCode: boolean;
      errorMessage: string;
      showPopover?: boolean;
      popoverContentTitle?: string;
      popoverContent?: string;
      popoverText?: string;
    }) => {
      const underWriterApprovalCode = get(quote, "approvalCode");
      const hasUnderWriterConsent = get(quote, "hasUnderwriterApproved", false);

      if (question.key === "aluminumWiring") {
        question.showPopover = true;
        question.popoverContentTitle = "Required Amps";
        question.popoverContent = `
        Insurors: 100 Amps. <br>
        American: Summit 100 Amps.  <br>
        Roadrunner: 60 Amps.`;
        question.popoverText = "View List";
      }

      formattedQuestions.push(
        ...[
          ...useFormSection([
            useField(
              {
                type: "radio_button_group",
                emitRadioButtonChangeEvent: false,
                preamble: question.question,
                key: question.key,
                options: selectOptions.yesNoOptions,
                required: true,
                showPopover: question.showPopover,
                popoverContentTitle: question.popoverContentTitle,
                popoverContent: question.popoverContent,
                popoverText: question.popoverText,
                validations: [
                  {
                    method: "custom",
                    error: question?.errorMessage,
                    customValidator: (value: any) => {
                      if (
                        question.validResponse === "any" ||
                        (value && value == question.validResponse) ||
                        (question.needsApprovalCode
                          ? underWriterApprovalCode && hasUnderWriterConsent
                          : false)
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                  }
                ]
              },
              ["mb-8"]
            )
          ])
        ]
      );
    }
  );
  return formattedQuestions;
}
